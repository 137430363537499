<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ item ? 'Update Course Form' : 'New Course Form' }}
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <FormText
          v-model="form.title"
          label="Title"
          asterisk
          rules="required"
        />
        <FormNumber
          v-model="form.course_weeks"
          label="Duration (Weeks)"
          asterisk
          rules="required"
        />
        <FormNumber
          v-model="form.holiday_weeks"
          label="Holidays (Weeks)"
          asterisk
          rules="required"
        />
        <WeekdaysSelect
          v-model="form.weekdays"
          label="Weekdays"
          asterisk
          rules="required"
          multiple
        />
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success pull-right"
        @click="saveCourse"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText, FormNumber } from '@/components/Inputs';

import { extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';
import WeekdaysSelect from '@/components/Inputs/selects/WeekdaysSelect';

extend('min', min);
extend('required', required);

export default {
  components: {
    FormText,
    FormNumber,
    WeekdaysSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      title: null,
      course_weeks: 1,
      holiday_weeks: 1,
      weekdays: [],
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveCourse() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.form.course_id) {
            this.request(`${this.$API.COURSES}/${this.form.course_id}`, 'put', { ...this.form }, () => {
              this.fireSuccess('Course updated successfuly');
              this.$emit('onSaved');
              this.close();
            });
          } else {
            this.request(this.$API.COURSES, 'post', { ...this.form }, () => {
              this.fireSuccess('Course created successfuly');
              this.$emit('onSaved');
              this.close();
            });
          }
        } else {
          this.fireError('Please, complete all the required fields before.');
        }
      });
    },
  },
};
</script>
<style></style>
