<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newCourse"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Course</md-tooltip>
            </md-button>

            <div
              class="d-inline-block pull-right"
            >
              <md-switch
                v-model="filters.show_inactives"
                @change="$refs.vtable.init()"
              >
                Show Inactives
              </md-switch>
            </div>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getCourses"
              @onEdit="onEdit"
              @onDeactivate="onDeactivate"
              @onActivate="onActivate"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <CoursesForm
      v-if="showModal"
      :item="selectedCourse"
      @close="closeModal"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import CoursesForm from './CoursesForm.vue';

const dowToString = (dow) => {
  const days = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };
  return days[dow];
};

export default {
  components: {
    VueTable,
    CoursesForm,
  },
  data() {
    return {
      filters: {
        paginate: true,
        show_inactives: false,
      },
      vTable: {
        headers: [
          {
            title: 'course_id',
            mask: 'course id',
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'course_weeks',
            mask: 'duration (weeks)',
            sortable: true,
          },
          {
            title: 'holiday_weeks',
            mask: 'holidays (weeks)',
            sortable: true,
          },
          {
            title: 'weekdays',
            callback: (item) => item.map((x) => dowToString(x)).join(', '),
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deactivate Course',
            callback: 'onDeactivate',
            icon: 'close',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Activate Course',
            callback: 'onActivate',
            icon: 'done_all',
          },
        ],
        values: {},
      },
      showModal: false,
      selectedCourse: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getCourses(params) {
      this.request(this.$API.COURSES, 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onDeactivate = x.is_active;
          x.onActivate = !x.is_active;
          return x;
        });
      });
    },
    newCourse() {
      this.showModal = true;
      this.selectedCourse = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedCourse = { ...item };
    },
    closeModal() {
      this.showModal = false;
      this.selectedCourse = null;
    },
    onActivate(item) {
      this.fireConfirm(
        'Activating Course',
        `Are you sure you want to activate this Course (${item.title})?`,
      )
        .then(() => {
          this.request(`${this.$API.COURSES}${item.course_id}`, 'put', {
            ...item,
            is_active: true,
          }, () => {
            this.fireSuccess('Course activated successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
    onDeactivate(item) {
      this.fireConfirm(
        'Deactivating Course',
        `Are you sure you want to deactivate this Course (${item.title})?`,
      )
        .then(() => {
          this.request(`${this.$API.COURSES}${item.course_id}`, 'delete', {}, () => {
            this.fireSuccess('Course deactivated successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
